
import { defineComponent, ref } from "vue";
import KTUserMenu from "@/presentation/layout/header/partials/UserMenu.vue";
import { COMPONENT_NAME } from "@/presentation/constants/Components";
import { useStore } from "vuex";
import { ChangeTenantEstateModel } from "@/domain/auth/change-tenant-estate/model/ChangeTenantEstateModel";
import { TenantEstateModel } from "@/domain/user/tenant-estate/model/TenantEstateModel";
import { useRouter } from "vue-router";

export default defineComponent({
  name: COMPONENT_NAME.TOPBAR,
  props: {
    activeTenantName: String,
    tenantEstates: Object,
    email: String,
    name: String,
    surName: String,
    phone: String,
    countryCode: String,
  },
  components: {
    KTUserMenu,
  },
  setup() {
    const store = useStore();
    const userController = store.state.ControllersModule.userController;
    const router = useRouter();
    const changedTenant = ref<number>(0);

    const changeTenant = (tenantEstate: TenantEstateModel) => {
      const changeTenantEstateModel: ChangeTenantEstateModel = {
        tenantEstateId: Number(tenantEstate.id),
      };

      userController
        .changeTenantEstate(changeTenantEstateModel)
        .then((response) => {
          if(response.isSuccess){
            window.location.href="/dashboard"
            /*
                        location.reload();
            router.push({ name: "dashboard" });
            */

            /*
            changedTenant.value += 1;
              store.dispatch("changeTenant", changedTenant.value);

            router.push({ name: "dashboard" });
*/
          }
        });
    };

    return {
      changeTenant
    };
  },
});
